<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<div
					:class="!showFilters ? 'btn-primary' : 'btn-secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters;">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</div>
				<div class="btn btn-lg">
					{{ datePeriod }}
				</div>
				<b-button
					:disabled="loading"
					class="float-right"
					variant="success"
					@click="exportReport">
					<i :class="!loading ? 'fas fa-file-excel' : 'fas fa-spinner fa-spin'" /> {{ translate('export') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									v-for="dateRangeInfo in dateRanges"
									:key="dateRangeInfo"
									:value="dateRangeInfo">
									{{ translate(dateRangeInfo) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="customDateRange"
						class="col-sm-12"
						:class="(customDateRange ^ isExpensesTab) ? 'col-md-3' : 'col-md-4'">
						<div
							class="form-group">
							<label
								for="startDate"
								class="label">{{ translate('start_date') }}</label>
							<v-date-picker
								id="startDate"
								v-model="startDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="startDate"
								show-caps />
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm-12"
						:class="(customDateRange ^ isExpensesTab) ? 'col-md-3' : 'col-md-4'">
						<div
							class="form-group">
							<label
								for="endDate"
								class="label">{{ translate('end_date') }}</label>
							<v-date-picker
								id="endate"
								v-model="endDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="endDate"
								show-caps />
						</div>
					</div>
					<div
						v-if="!isExpensesTab"
						class="col-sm-12"
						:class="(customDateRange ^ isExpensesTab) ? 'col-md-3' : 'col-md-4'">
						<div class="form-group">
							<label
								for="countryName"
								class="label">{{ translate('country') }}</label>

							<select
								id="countryName"
								v-model="filters.country"
								:disabled="staffCountry"
								name="country"
								class="form-control"
								@change="changeCountry">
								<template v-if="!staffCountry">
									<option :value="undefined">
										{{ translate('all') }}
									</option>
									<option
										v-for="country in countries"
										:key="country.attributes.code"
										:value="country.attributes.code">
										{{ translate(country.attributes.code.toLowerCase()) }}
									</option>
								</template>
								<template v-if="staffCountry">
									<option :value="countryCode">
										{{ translate(countryCode.toLowerCase()) }}
									</option>
								</template>
							</select>
						</div>
					</div>
					<div
						v-if="!isExpensesTab"
						class="col-sm-12"
						:class="(customDateRange ^ isExpensesTab) ? 'col-md-3' : 'col-md-4'">
						<div class="form-group">
							<label
								for="regionName"
								class="label">{{ translate('region') }}</label>

							<select
								id="regionName"
								v-model="filters.region_id"
								:disabled="typeof filters.country === 'undefined' || filters.country === null"
								name="region"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="region in regions"
									:key="`r_${region.id}`"
									:value="region.id">
									{{ region.attributes.name }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="!isExpensesTab"
						class="col-sm-12"
						:class="(customDateRange ^ isExpensesTab) ? 'col-md-3' : 'col-md-4'">
						<div class="form-group">
							<label
								for="method"
								class="label">{{ translate('payment_method') }}</label>

							<select
								id="payment_method"
								v-model="filters.payment_method"
								name="payment_method"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="method in methods"
									:key="method.attributes.method_code_name"
									:value="method.attributes.method_code_name">
									{{ translate(`sales_${method.attributes.method_code_name.toLowerCase()}`) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="!isExpensesTab"
						class="col-sm-12"
						:class="(customDateRange ^ isExpensesTab) ? 'col-md-3' : 'col-md-4'">
						<div
							class="form-group">
							<label
								for="orderId"
								class="label">{{ translate('order_id') }}</label>
							<input
								id="orderId"
								v-model="filters.orderId"
								:placeholder="translate('type_order')"
								type="text"
								name="orderId"
								class="form-control">
						</div>
					</div>
					<div
						v-if="!isExpensesTab && companies.length > 1"
						class="col-sm-12"
						:class="(customDateRange ^ isExpensesTab) ? 'col-md-3' : 'col-md-4'">
						<div
							class="form-group">
							<label
								for="company"
								class="label">{{ translate('division') }}</label>
							<select
								id="company"
								v-model="filters.company"
								name="company"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="company in companies"
									:key="company.attributes.code"
									:value="company.attributes.code">
									{{ translate(company.attributes.code) }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<b-row>
			<b-col class="col-12">
				<b-tabs v-model="tabIndex">
					<b-tab
						v-for="(tabInfo, index) in tabs"
						:key="index"
						:lazy="true">
						<template slot="title">
							<router-link
								:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
								:to="{ name: tabInfo.name, query }"
								class="list-group-item btn text-left">
								{{ translate(tabInfo.translate_key) }}
							</router-link>
						</template>
						<template
							v-if="tabIndex === index"
							class="p-0">
							<router-view />
						</template>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>
<script>

import {
	Report, SalesPaymentMethods as methodsMessages, Countries, Purchase, Grids,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import {
	PERIOD_FILTERS as dateRanges,
	V_CALENDAR_CONFIG as calendarConfig,
	DATE_START_FILTER as dateStartFilter,
	DATE_END_FILTER as dateEndFilter,
	allTime,
	custom,
} from '@/settings/Dates';
import DataFilter from '@/components/DataFilter';
import Sales from '@/util/Report';
import Country from '@/util/Country';
import PaymentMethods from '@/util/PaymentMethods';
import Company from '@/util/Company';

const countries = new Country();
const regions = new Country();
const methods = new PaymentMethods();
const companies = new Company();

export default {
	name: 'Sales',
	messages: [Report, methodsMessages, Countries, Purchase, Grids],
	components: { DataFilter },
	mixins: [FiltersParams],
	data() {
		return {
			dateRanges,
			allTime,
			custom,
			calendarConfig,
			loading: false,
			sales: new Sales(),
			alert: new this.$Alert(),
			dateRange: dateRanges.today,
			rangeStart: '',
			rangeEnd: '',
			tabIndex: 0,
			tabs: [
				{
					name: 'OrdersInformation',
					translate_key: 'orders_information',
				},
				{
					name: 'OrdersProductsInformation',
					translate_key: 'orders_product_information',
				},
				{
					name: 'SettlementsExpenses',
					translate_key: 'settlement_expenses',
				},
			],
			countries: [],
			regions: [],
			methods: [],
			companies: [],
		};
	},
	computed: {
		query() {
			return this.$route.query;
		},
		datePeriod() {
			return this.getDatePeriod();
		},
		staffCountry() {
			return this.$user.details().country !== undefined;
		},
		countryCode() {
			return this.$user.details().country.iso_code_2;
		},
		isExpensesTab() {
			return this.$route.name === 'SettlementsExpenses';
		},
		customDateRange() {
			return this.dateRange === this.dateRanges.custom;
		},
	},
	mounted() {
		countries.getCountries({ also_pickup_stores: true, include_blacklist: true }).then((response) => {
			this.countries = response;
		});

		methods.getAllPaymentMethods().then((response) => {
			this.methods = response;
		});

		companies.getReportCompanies().then((response) => {
			this.companies = response;
		});

		if (typeof this.$route.name !== 'undefined') {
			this.tabIndex = this.tabs.findIndex((tabInfo) => tabInfo.name === this.$route.name);
		}
		const { query } = this.$route;
		this.handleChangeCountry(query.country, query.region_id);

		const dateKeys = Object.keys(query);
		if (typeof this.dateRanges[query.dateRange] !== 'undefined') {
			this.dateRange = query.dateRange;
		} else if (typeof query.dateRange !== 'undefined' && !Object.values(this.dateRanges).includes(query.dateRange)) {
			this.dateRange = this.dateRanges.today;
			query.dateRange = this.dateRanges.today;
		} else if (dateKeys.includes(dateStartFilter) || dateKeys.includes(dateEndFilter)) {
			this.dateRange = this.dateRanges.custom;
		} else if (typeof query.dateRange === 'undefined') {
			this.dateRange = this.dateRanges.today;
		}
		if (this.staffCountry) {
			this.filters.country = this.countryCode;
		}

		return null;
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			this.$router.push({ name: this.$route.name, query: options });
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
		exportReport() {
			const options = { ...this.query };
			const totals = new Sales();
			this.loading = true;

			totals.exportSalesDetailsReport(options).then(() => {
				this.alert.toast('success', this.translate('export_queued'), { timer: 6000 });
			}).finally(() => {
				this.loading = false;
			});
		},
		changeCountry(event) {
			this.handleChangeCountry(event.target.value);
		},
		handleChangeCountry(countryCode, defaultValue = null) {
			this.filters.region_id = undefined;
			if (typeof countryCode === 'undefined' || countryCode === null || countryCode.length === 0) {
				this.regions = [];
			} else {
				regions.getStates(countryCode).then((response) => {
					this.regions = response;
					if (defaultValue) {
						this.filters.region_id = defaultValue;
					}
				});
			}
		},
	},
};
</script>
